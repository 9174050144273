import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";

const Attempt = ({visible, setVisible}) => {
    const dispatch = useDispatch()
    const [answer, setAnswer] = useState('')
    const user = useSelector(state=>state.main.user)
    const [verPhoto, setVerPhoto] = useState(1)

    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getTxt() {
            const num = getRandomInt(1, 10)
            setVerPhoto(num)
        }

        getTxt()
    }, [])


    let show_mod
    let show_over

    if (visible) {
        show_mod = 'modal-show'
        show_over = 'overflow-show'

    } else {
        show_mod = ''
        show_over = ''
    }


    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getTxt() {
            const num = getRandomInt(1, 3)
            let txt = data[num]
            setAnswer(txt)
        }

        getTxt()
    }, [user])


    const data = {
        1:<><strong style={{color: "red"}}>Начислено!</strong> Потом не говори, что мы в тебя не верили.</>,
        2:<><strong style={{color: "red"}}>Держи еще!</strong>. Нам это ничего не стоит.</>,
    }

    function attempt_btn () {
        setVisible(false)
        dispatch(setActivePanel('game'))
    }

    return (
        <div className={`overflow ${show_over}`}>
        <div className={`modal ${show_mod}`} id="try-modal">
            <img className="modal__head" src={`images/horns/head_horns_${verPhoto}.png`} width="153" height="199" alt=""/>
                <div className="modal__message">
                    <p>{answer}</p>
                </div>
                <button onClick={attempt_btn} className="modal__button button button_type_first">Вращать рулетку</button>
        </div>
        </div>
    );
};

export default Attempt;