import React, {useEffect, useState} from 'react';
import WinnerItem from "./WinnerItem";
import ServerConnect from "../service";

const WinnersList = () => {

    const [winners, setWinners] = useState(false)

    useEffect(() => {
        ServerConnect.sendGet('/api/winners')
            .then(data => {setWinners(data)})
    }, [])

    let content = []

    if (winners) {
        for (let i = 0; i < winners.length; i++) {
            content.push(<WinnerItem
                photo={winners[i].client.photo_200}
                first_name={winners[i].client.first_name}
                last_name={winners[i].client.last_name}
                time={winners[i].time}
            />)
        }
    }

    return (
        <ul className="uk-slider-items uk-child-width-1-4 winners-block__list">
            {content}
        </ul>
    );
};

export default WinnersList;