import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import bridge from "@vkontakte/vk-bridge";
import {setUser} from "./store/mainReducer";
import ServerConnect from "./service";
import Init from "./panels/Init";
import Rules from "./panels/Rules";
import Game from "./panels/Game";
import Code from "./panels/Code";
import Winners from "./panels/Winners";
import WinPage from "./panels/WinPage";

const App = () => {

  const dispatch = useDispatch()
  const activePanel = useSelector(state => state.main.activePanel)

  useEffect(() => {
    async function fetchData() {
      const user = await bridge.send('VKWebAppGetUserInfo');
      if (user) {
        return user;
      }
    }

    fetchData().then(data => get_user_info(data))

  }, [])

  async function get_user_info (user) {
    let response = await fetch(ServerConnect.api_url + '/api/user/' + user.id);
    if (response.status === 204) {
      let context = {
        vk_id: user.id,
        first_name: user.first_name,
        last_name: user.last_name,
        photo_200: user.photo_200,
      }
      ServerConnect.sendPost('/api/user/'+user.id, context)
          .then(data => dispatch(setUser(data)))
    } else {
      let user_info = await response.json()
      dispatch(setUser(user_info))
    }
  }


  let content = []
  if (activePanel === 'init') {
    content.push(<Init/>)
  } else if (activePanel === 'rules') {
    content.push(<Rules/>)
  } else if (activePanel === 'game') {
    content.push(<Game/>)
  } else if (activePanel === 'code') {
    content.push(<Code/>)
  } else if (activePanel === 'winners') {
    content.push(<Winners/>)
  } else if (activePanel === 'winpage') {
    content.push(<WinPage/>)
  }

  return (
      <div className='app'>
        {content}
      </div>
  );
}
export default App;

