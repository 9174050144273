import React, {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";
import Footer from "../components/Footer";
import WinnersList from "../components/WinnersList";

const Winners = () => {

    const dispatch = useDispatch()

    useEffect(()=>{
        let winnersBlock = document.getElementById('winners-block')
        if(window.innerWidth >= 600){
            UIkit.slider(winnersBlock).startAutoplay();
        } else {
            winnersBlock.removeAttribute('uk-slider')
        }
    },[])

    return (
        <div className="container winners-page">
            <button onClick={()=>dispatch(setActivePanel('game'))} className="container__link-button">Назад</button>
            <div className="winners-page__content">
                <div className="uk-slider-container winners-block" {...{ "uk-slider": "" }} id="winners-block">
                    <div className="winners-page__header">
                        <div className="winners-page__head-block">
                            <img src="images/raw-head.png" width="68" height="96" alt=""/>
                                <p><span className="gradient-text">Им реально<br/>
                                повезло</span></p>
                        </div>
                        <div className="winners-page__arrows">
                            <button className="winners-page__arrow-left" uk-slider-item="previous">
                                <img src="images/arrow.svg" alt=""/>
                            </button>
                            <button className="winners-page__arrow-right" uk-slider-item="next">
                                <img src="images/arrow.svg" alt=""/>
                            </button>
                        </div>
                    </div>
                    <WinnersList/>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default Winners;