import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const Init = () => {

    const [progress, setProgress] = useState('progress-10');
    const dispatch = useDispatch()
    const [initTxt, setInitTxt] = useState('')


    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getTxt() {
            const num = getRandomInt(1, 5)
            let txt = data[num]
            setInitTxt(txt)
        }
        getTxt()
    }, [])


    useEffect(()=>{
        setProgress('progress-100')
        setTimeout(()=>{dispatch(setActivePanel('rules'))},3000)
    },[])

    const data = {
        1:'заГрузия...',
        2:'Смазываем лотерейный механизм...',
        3:'Снимаем чехол с призовыдающего аппарата...',
        4:'Полируем большую красную кнопку...'}

    return (
        <div className="container start-screen">
            <div className="start-screen__roulette">
                <img className="start-screen__ring" src="images/roulettes/roulette-ring2.png" alt=""/>
            </div>
            <a href="#" className="start-screen__logo">
                <img src="images/logo.png" width="211" height="104" alt=""/>
            </a>
            <div className="progress-bar intro-block__progress-bar">
                <div className="progress-bar__line-wrapper">
                    <div className={"progress-bar__line " + progress}/>
                </div>
                <p className="progerss-bar__caption">{initTxt}</p>
            </div>
        </div>
    );
};

export default Init;