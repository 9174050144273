import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setUser, setWinItem} from "../store/mainReducer";
import Footer from "../components/Footer";
import Lose from "../components/modals/Lose";
import Win from "../components/modals/Win";
import ServerConnect from "../service";

const Game = () => {

    const user = useSelector(state=>state.main.user)
    const dispatch = useDispatch()
    const [game, setGame] = useState('')
    const [isGame, setIsGame] = useState(false)
    const isDisabledZero = (isGame || user.attempts <= 0) ? [true, '40%'] : [false, '100%']
    const isDisabled = (isGame) ? [true, '40%'] : [false, '100%']
    const [winModal, setWinModal] = useState(false)
    const [loseModal, setLoseModal] = useState(false)
    const [verPhoto, setVerPhoto] = useState(2)
    const [verRol, setVerRol] = useState(1)

    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getVerPhoto() {
            const num = getRandomInt(2, 10)
            setVerPhoto(num)
        }
        function getRol() {
            const ver = getRandomInt(1,9)
            setVerRol(ver)
        }

        getVerPhoto()
        getRol()
    }, [])
    function game_run () {
        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }
        let num

        const data = ServerConnect.sendGet('/api/game/' + user.vk_id)
            .then(data => {
                dispatch(setUser(data.user))
                if (data.status === 'lose') {
                    num = getRandomInt(1,8)
                    setIsGame(true)
                    setGame(`wheel-block__ring_item_${num}`)
                    setTimeout(()=>{setIsGame(false), setLoseModal(true)},5100)
                } else if (data.status === 'win') {
                    num = 8
                    setIsGame(true)
                    dispatch(setWinItem(data.prize))
                    setGame(`wheel-block__ring_item_${num}`)
                    setTimeout(()=>{setIsGame(false), setWinModal(true)},5100)
                }})
    }

    return (
        <>
        <div className="container roulette-page">
            <button onClick={()=>dispatch(setActivePanel('winners'))} className="container__link-button">Победители</button>
            <div className="roulette-page__content">
                <div className="roulette-page__roulette-info roulette-info">
                    <div className="roulette-info__head-block">
                        <img src={`images/beards/beard_${verPhoto}.gif`} width='120px' alt=""/>
                            <p><span className="gradient-text">Рулетка работает!<br/>
                        Крутите барабанс</span></p>
                    </div>
                    <button onClick={game_run}
                            disabled={isDisabledZero[0]}
                            style={{opacity:isDisabledZero[1]}}
                            className="button button_type_first roulette-info__button">
                        Крутанидзе
                        <span className="button__counter">{user.attempts}</span>
                    </button>
                    <button
                        disabled={isDisabled[0]}
                        style={{opacity:isDisabled[1]}}
                        onClick={()=>dispatch(setActivePanel('code'))}
                        className="button button_type_second">Получить поп-итку</button>
                </div>
                <div className="roulette-page__wheel-block wheel-block">
                    <div className="wheel-block__wrapper">
                        <img className="wheel-block__logo" src="images/logo.png" width="116" height="58" alt=""/>
                            <img className="wheel-block__arrow" src="images/roulette-arrow.png" alt=""/>
                                <div className="wheel-block__ring-wrapper">
                                    <img className={`wheel-block__ring ${game}`}
                                         src={`images/roulettes/roulette-ring${verRol}.png`}
                                         width="428px" height="428px"
                                         loading="lazy" decoding="async" alt=""/>
                                </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
        <Lose visible={loseModal} setVisible={setLoseModal}/>
        <Win visible={winModal} setVisible={setWinModal}/>
        </>
    );
};

export default Game;