import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../../store/mainReducer";

const Win = ({visible, setVisible}) => {

    const [answer, setAnswer] = useState('')
    const user = useSelector(state=>state.main.user)
    const dispatch = useDispatch()
    const [verPhoto, setVerPhoto] = useState('')
    const item = useSelector(state => state.main.item)

    let show_mod
    let show_over

    if (visible) {
        show_mod = 'modal-show'
        show_over = 'overflow-show'

    } else {
        show_mod = ''
        show_over = ''
    }


    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getTxt() {
            const num = getRandomInt(1, 12)
            setVerPhoto(num)
        }

        getTxt()
    }, [item])

    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getTxt() {
            const num = getRandomInt(1, 3)
            let txt = data[num]
            setAnswer(txt)
        }

        getTxt()
    }, [user])


    let prize = []

    if (item===1) {
        prize.push(<img className="win-modal__prize-pic" src="images/prize4.png" width="80" height="104" alt=""/>)
    } else if (item===2) {
        prize.push(<img className="win-modal__prize-pic" src="images/prize3.png" width="85" height="80" alt=""/>)
    } else if (item===3) {
        prize.push(<img className="win-modal__prize-pic" src="images/prize1.png" width="75" height="100" alt=""/>)
    } else if (item===4) {
        prize.push(<img className="win-modal__prize-pic" src="images/prize2.png" width="65" height="110" alt=""/>)
    }


    const data = {
        1:'Вот это прикол! Ты выиграл...',
        2:'Нормально поперло! Ты выиграл...',
        3:'Хорош, оставь призы другим! Ты выиграл...',
    }

    function win_btn () {
        setVisible(false)
        dispatch(setActivePanel('winpage'))
    }

    return (
            <div className={`overflow ${show_over}`}>
            <div className={`modal win-modal ${show_mod}`} id="win-modal">
                <div className="win-modal__header">
                    <img src={`images/caps/head_cap_${verPhoto}.png`} width="129" height="153" alt=""/>
                        <p>
                            <span>{answer}</span>
                        </p>
                </div>
               <div className="win-modal__prize-block">
                   {prize}
               </div>
                    <p className="win-modal__text text">Это твой приз, его можно забрать
                        прямо сейчас! и еще
                        одна строчка на всякий случай</p>
                    <button onClick={win_btn} className="win-modal__button button button_type_first">Получить приз</button>
            </div>
             </div>
    );
};

export default Win;