import React from 'react';

const WinnerItem = ({photo, time, first_name, last_name}) => {
    return (
        <li>
            <div className="user-block">
                <div className="user-block__wrpapper">
                    <img className="user-block__pic" src={photo} width="77" height="77"
                         alt=""/>
                    <p className="user-block__date">{time}</p>
                    <div className="user-block__name">{first_name}<br/>{last_name}</div>
                </div>
            </div>
        </li>
    );
};

export default WinnerItem;