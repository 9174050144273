import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";
import Footer from "../components/Footer";

const Rules = () => {
    const dispatch = useDispatch()
    const [verPhoto, setVerPhoto] = useState(1)

    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getTxt() {
            const num = getRandomInt(1, 12)
            setVerPhoto(num)
        }

        getTxt()
    }, [])

    return (
        <div className="container rules-page">
            <div className="rules-page__content">
                <div className="rules-page__head-block">
                    <img src={`images/caps/head_cap_${verPhoto}.png`} width="169" height="210" alt=""/>
                        <p><span className="gradient-text">Я хочу сыграть<br/>
                    с тобой в игру!</span></p>
                </div>
                <p className="text rules-page__text">
                    Удивительная новогодняя рулетка от Comedy Radio, в которой каждый может испытать удачу и
                    чувство настоящего новогоднего волшебства. Ведь то, что она работает - уже настоящее чудо!
                    Не упусти возможность забрать классный новогодний подарок от Comedy Radio или обосновано ругаться,
                    что в интернете все куплено.
                </p>
                <div className="rules-page__buttons">
                    <button onClick={()=>dispatch(setActivePanel('game'))} className="button button_type_first">Начать игру</button>
                </div>
                <a href="https://vk.com/topic-42981815_48359273" target="_blank" className="rules-page__link">Правила и призы</a>
            </div>
            <Footer/>
        </div>
    );
};

export default Rules;