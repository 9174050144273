import React from 'react';

const Footer = () => {
    return (
        <div className="container__bottom">
            <a href="#" className="logo">
                <img src="images/logo.png" width="88" height="44" alt=""/>
            </a>
        </div>
    );
};

export default Footer;