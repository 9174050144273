import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel, setUser} from "../store/mainReducer";
import Footer from "../components/Footer";
import Attempt from "../components/modals/Attempt";
import WrongCode from "../components/modals/WrongCode";
import ServerConnect from "../service";

const Code = () => {
    const dispatch = useDispatch()
    const [codeModal, setCodeModal] = useState(false)
    const [wrongModal, setWrongModal] = useState(false)
    const [wrongTxt, setWrongTxt] = useState('')
    const [code, setCode] = useState('')
    const user = useSelector(state=>state.main.user)

    function code_run () {

        let context = {'code': code.toLowerCase()}
        const data = ServerConnect.sendPost('/api/code/' + user.vk_id, context)
            .then(data => {
                if (data.status === 'wrong_code') {
                    setWrongTxt('Такого кода нет')
                    setWrongModal(true)
                } else if (data.status === 'wrong_date') {
                    setWrongTxt('Срок действия кода истек')
                    setWrongModal(true)
                } else if (data.status === 'repeat') {
                    setWrongTxt('Вы уже вводили этот код')
                    setWrongModal(true)
                } else if (data.status === 'expire') {
                    setWrongTxt('Срок действия кода истек')
                    setWrongModal(true)
                } else if (data.status === 'right') {
                    dispatch(setUser(data.user))
                   setCodeModal(true)
                }
            })
        setCode('')
    }

    return (
        <>
        <div className="container code-page" onClick={()=> {setCodeModal(false), setWrongModal(false)}}>
            <button onClick={()=>dispatch(setActivePanel('game'))} className="container__link-button">Назад</button>
            <div className="code-page__content">
                <Attempt visible={codeModal} setVisible={setCodeModal}/>
                <WrongCode text={wrongTxt} visible={wrongModal} setVisible={setWrongModal}/>
                <p className="code-page__question">
                <span className="gradient-text">Как вращать<br/>
                    еще раз?</span>
                </p>
                <div className="code-page__head-block">
                    <img src="images/head-hat.png" width="55" height="105" alt=""/>
                        <p><span className="gradient-text">Слушай Comedy Radio, в эфире каждого шоу
                            будет звучать секретный код, который нужно ввести в поле ниже.</span></p>
                </div>
                <div className="code-page__form">
                    <input
                        type="text"
                        className="code-page__input"
                        placeholder="ВВЕДИТЕ КОД"
                        value={code}
                        onChange={(e)=>setCode(e.target.value)}
                    />
                        <button onClick={code_run} className="button button_type_first code-page__submit">Активировать код</button>
                </div>
            </div>
            <Footer/>
        </div>
        <Attempt visible={codeModal} setVisible={setCodeModal}/>
        <WrongCode text={wrongTxt} visible={wrongModal} setVisible={setWrongModal}/>
        </>
    );
};

export default Code;