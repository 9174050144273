import React, {useEffect, useState} from 'react';
import {setActivePanel} from "../../store/mainReducer";

const WrongCode = ({text, visible, setVisible}) => {

    const [btnTxt, setBtnTxt] = useState('')
    const [verPhoto, setVerPhoto] = useState('')

    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getTxt() {
            const num = getRandomInt(1, 10)
            setVerPhoto(num)
        }

        getTxt()
    }, [])

    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getTxt() {
            const num = getRandomInt(1, 5)
            let txt = btn_txt[num]
            setBtnTxt(txt)
        }

        getTxt()
    }, [])

    const btn_txt = {
        1: 'Ок, смирился',
        2: 'Принято, понято',
        3: 'Ну и ладно',
        4: 'Медленно закрыть',
    }

    let show_mod
    let show_over

    if (visible) {
        show_mod = 'modal-show'
        show_over = 'overflow-show'

    } else {
        show_mod = ''
        show_over = ''
    }

    function wrong_btn() {
        setVisible(false)
    }


    return (
        <div className={`overflow ${show_over}`}>
            <div className={`modal ${show_mod}`} id="fail-code-modal">
                <img className="modal__head" src={`images/horns/head_horns_${verPhoto}.png`} width="153" height="199" alt=""/>
                <div className="modal__message">
                    <p>{text}</p>
                </div>
                <button onClick={wrong_btn} className="modal__button button button_type_first">{btnTxt}</button>
            </div>
        </div>
    );
};

export default WrongCode;