import React, {useEffect, useState} from 'react';
import {useSelector} from "react-redux";

const Lose = ({visible, setVisible}) => {

    const [answer, setAnswer] = useState('')
    const user = useSelector(state => state.main.user)
    const [btnTxt, setBtnTxt] = useState('')
    const [verPhoto, setVerPhoto] = useState('')

    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getTxt() {
            const num = getRandomInt(1, 10)
            setVerPhoto(num)
        }

        getTxt()
    }, [])

    let show_mod
    let show_over

    if (visible) {
        show_mod = 'modal-show'
        show_over = 'overflow-show'

    } else {
        show_mod = ''
        show_over = ''
    }


    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getTxt() {
            const num = getRandomInt(1, 5)
            let txt = btn_txt[num]
            setBtnTxt(txt)
        }

        getTxt()
    }, [user])

    const btn_txt = {
        1: 'Ок, смирился',
        2: 'Принято, понято',
        3: 'Ну и ладно',
        4: 'Медленно закрыть',
    }

    useEffect(() => {

        function getRandomInt(min, max) {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min; //Максимум не включается, минимум включается
        }

        function getTxt() {
            const num = getRandomInt(1, 5)
            let txt = data[num]
            setAnswer(txt)
        }

        getTxt()
    }, [user])

    const data = {
        1: 'Зато тебя любят дома…',
        2: 'Ты выиграл, ведь проиграл не в «Игре Кальмара»',
        3: 'В себя поверил? Ок! Попробуй еще',
        4: 'Тебе бы не рулетку крутить, а голландский штурвал. Попробуй еще раз!',
        5: 'У Стива Джобса тоже не сразу вышло. Попробуй еще.',
        6: 'Наш подарок останется для тебя крашем. Сорян! Пробуй еще.',
        7: 'Ой, ну только не плачь. Лучше еще раз попробуй.',
        8: 'Без подарка, зато на чиле, на расслабоне. В другой раз повезет.',
        9: 'Не выиграл? Жиза… Давай еще!',
    }

    function lose_btn() {
        setVisible(false)
    }

    return (
        <div className={`overflow ${show_over}`}>
            <div className={`modal ${show_mod}`} id="fail-modal">
                <img className="modal__head" src={`images/horns/head_horns_${verPhoto}.png`} width="153" height="199" alt=""/>
                <div className="modal__message">
                    <p>{answer}</p>
                </div>
                <button onClick={lose_btn} className="modal__button button button_type_first">{btnTxt}</button>
            </div>
        </div>
    );
};

export default Lose;