import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name:'main',
    initialState: {
        activePanel: 'init',
        user: null,
        item: null
    },
    reducers:{
        setActivePanel(state, action) {
               state.activePanel = action.payload
        },
        setUser(state,action){
            state.user = action.payload
        },
        setWinItem(state, action) {
            state.item = action.payload
        }
    }
})

export default mainSlice.reducer
export const {setActivePanel,setUser, setWinItem} = mainSlice.actions
