import React from 'react';
import {setActivePanel} from "../store/mainReducer";
import Footer from "../components/Footer";
import {useDispatch} from "react-redux";

const WinPage = () => {
    const dispatch = useDispatch()
    return (
        <div className="container rules-page">
            <button onClick={()=> {dispatch(setActivePanel('rules'))}} className="container__link-button">Назад</button>
            <div className="rules-page__content">
                <div className="rules-page__head-block">
                    <img src="images/caps/head_cap_1.png" width="169" height="210" alt=""/>
                    <p><span className="gradient-text">Браво<br/>
                    Ты победил!</span></p>
                </div>
                <p className="text rules-page__text">
                    Ещё раз тебя поздравляем! Скорее пиши в личные сообщения нашей группы "Я ПОБЕДИТЕЛЬ",
                    чтобы узнать как получить свой приз!
                    Будь терпелив - менеджеры обязательно ответят и ты получишь свой новогодний подарок!
                </p>
                <div className="rules-page__buttons">
                    <a href="https://vk.me/comedyradio" target="_blank" className="button button_type_first">Забрать приз</a>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

export default WinPage;